<template>
  <div class="aboutUs">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">关于我们</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <p>
        本站属于特殊行业，如果您未满18岁或您当地法律许可之法定年龄、或是对情色反感或是卫道人士建议您离开本站！本站归类为限制级、限定为成年者已具有完整行为能力且愿接受本站内影音内容、及各项条款之网友才可浏览，未满18岁谢绝进入。
      </p>
      <div class="urlBox">
        <div>商务合作 {{ businessUrl }}</div>
        <div class="copyBtn" @click="copyUrl(businessUrl)">复制</div>
      </div>
      <div class="urlBox">
        <div>官方邮箱 {{ mailUrl }}</div>
        <div class="copyBtn" @click="copyUrl(mailUrl)">复制</div>
      </div>
      <div class="urlBox">
        <div>下载地址 {{ dowonloadUrl }}</div>
        <div class="copyBtn" @click="copyUrl(dowonloadUrl)">复制</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      businessUrl: "",
      mailUrl: "",
      dowonloadUrl: "",
    };
  },
  created() {
    let appConfig = this.$store.getters.appConfig;
    appConfig.sourceList.forEach((i) => {
      if (i.type == "BusinessCooperation") {
        this.businessUrl = i.domain[0].url;
      }
      if (i.type == "GROUPEMAIl") {
        this.mailUrl = i.domain[0].url;
      }
      if (i.type == "LAND") {
        this.dowonloadUrl = i.domain[0].url;
      }
    });
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 复制链接
    copyUrl(text) {
      this.$copyText(text).then(
        (e) => {
          Toast("复制成功");
        },
        (e) => {
          Toast("复制失败");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  height: 100%;
  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #F3F3F3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 16px 0;
    box-sizing: border-box;
    font-size: 14px;
    color: #333;
    line-height: 30px;

    p {
      margin-bottom: 44px;
    }
    .urlBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 35px;
      .copyBtn {
        margin-top: 12px;
        width: 48px;
        height: 28px;
        font-size: 10px;
        border-radius: 28px;
        color: #424242;
        background: #FBD13D;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
